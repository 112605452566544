'use client'

import {FC, createContext, useContext, ReactNode} from 'react'
import {createIntl, createIntlCache} from 'react-intl'

import trMessages from './messages/tr.json'
import enMessages from './messages/en.json'

import {getCookie} from 'cookies-next'
import {getCountryByCountryId} from '@/i18n'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
type WithChildren = {
  children?: ReactNode
}
type Props = {
  selectedLang: 'tr' | 'en'
}
const initialState: Props = {
  selectedLang: 'tr',
}

function getConfig(): Props {
  if (typeof window !== 'undefined') {
    const ls = localStorage.getItem(I18N_CONFIG_KEY)
    if (ls) {
      try {
        return JSON.parse(ls) as Props
      } catch (er) {}
    }
  }
  return initialState
}

export function getLanguage(initialLang?: string): string {
  if (typeof window !== 'undefined') {
    const lang = getConfig().selectedLang
    return lang
  }
  return initialLang || 'tr' // Set your desired initial language here
}

// Side effect
export function setLanguage(language: string, countryId?: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: language}))
  if (!countryId) {
    window.location.reload()
    return
  }

  window.location.pathname = `/v2/${language}-${getCountryByCountryId(countryId).iso2}`
}

const I18nContext = createContext<Props>(initialState)
const useLang = () => {
  return useContext(I18nContext).selectedLang
}
const I18nContextProvider: FC<WithChildren> = ({children}) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

const cache = createIntlCache()
const getIntlInstance = (locale = getCookie('language') as string) => {
  let messages
  switch (locale) {
    case 'tr':
      messages = trMessages
      break
    case 'en':
      messages = enMessages
      break
    default:
      messages = trMessages
  }

  return createIntl(
    {
      locale,
      messages,
    },
    cache
  )
}

export {I18nContextProvider, useLang, getIntlInstance}
