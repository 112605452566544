'use client'

import {getLanguageCodeFromLocale} from '@/i18n'
import {useLocale, useTranslations} from 'next-intl'
import {useLang} from '@/i18n/i18n'
import {useIntl} from 'react-intl'

/**
 * Custom hook for translating messages using either react-intl or next-intl.
 * This method works well in both the AppRouter and the PagesRouter files.
 *
 * @param {string} id - The translation key/id.
 * @param {Record<string, string | number>} [options] - Optional parameters for the translation.
 * @returns {string} The translated message.
 */
export const useTranslate = (id: string, options?: Record<string, string | number>) => {
  try {
    // First, attempt to use react-intl for translation
    const intl = useIntl()
    return intl.formatMessage({id}, options)
  } catch (error) {
    // If react-intl fails, fallback to next-intl
    const t = useTranslations()
    return t(id, options)
  }
}

/**
 * Custom hook to get the current language.
 *
 * This hook attempts to retrieve the language using next-intl's useLocale hook,
 * and falls back to the useLang hook if useLocale fails.
 *
 * This method works well in both the AppRouter and the PagesRouter files.
 * But not in the server components of the AppRouter.
 *
 * @returns {string} The current language code.
 */
export const useGetCurrentLanguage = (): string => {
  try {
    // Attempt to get the locale using next-intl's useLocale hook
    const locale = useLocale()
    // Convert the locale to a language code
    return getLanguageCodeFromLocale(locale)
  } catch (error) {
    // If useLocale fails, fallback to using the useLang hook
    const lang = useLang()
    return lang
  }
}
